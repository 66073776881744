import monorail, {getDefaultPayload} from '../monorail';

interface ModelViewerBaseInfo {
  src: string,
  sessionModelViewerId: number,
}

interface ModelViewerCreated extends ModelViewerBaseInfo {
  iosSrc: string,
}

interface ModelViewerLoaded extends ModelViewerBaseInfo {
  timeToLoaded: number,
}

interface ModelViewerFailed extends ModelViewerBaseInfo {
  type: string,
}

export type TrackCreated = (info: ModelViewerCreated) => Promise<any>;
export type TrackLoaded = (info: ModelViewerLoaded) => Promise<any>;
export type TrackFailed = (info: ModelViewerFailed) => Promise<any>;
export type TrackInteract = (info: ModelViewerBaseInfo) => Promise<any>;

type MonorailModelViewer = {
  trackCreated: TrackCreated,
  trackLoaded: TrackLoaded,
  trackFailed: TrackFailed,
  trackInteract: TrackInteract,
};

const monorailModelViewer: MonorailModelViewer = {
  trackCreated(info: ModelViewerCreated): Promise<any> {
    const defaultPayload = getDefaultPayload();

    return monorail.produce({
      schemaId: 'online_store_media_model_viewer_created/1.0', 
      payload: {
        ...defaultPayload,
        ...info,
      },
    });
  },

  trackLoaded(info: ModelViewerLoaded): Promise<any> {
    const defaultPayload = getDefaultPayload();

    return monorail.produce({
      schemaId: 'online_store_media_model_viewer_loaded/1.0', 
      payload: {
        ...defaultPayload,
        ...info,
      },
    });
  },

  trackFailed(info: ModelViewerFailed): Promise<any> {
    const defaultPayload = getDefaultPayload();

    return monorail.produce({
      schemaId: 'online_store_media_model_viewer_failed/1.0', 
      payload: {
        ...defaultPayload,
        ...info,
      },
    });
  },

  trackInteract(info: ModelViewerBaseInfo): Promise<any> {
    const defaultPayload = getDefaultPayload();

    return monorail.produce({
      schemaId: 'online_store_media_model_viewer_interact/1.0', 
      payload: {
        ...defaultPayload,
        ...info,
      },
    });
  },
};

export default monorailModelViewer;
